import React, { Suspense, lazy } from 'react';

import SideControlPanel from '../SideControlPanel';
import TabBarPanel from '../TabBarPanel';
import Page from '../Page';
import ElementControlPanel from '../ElementControlPanel';

import ZoomControl from '../ZoomControl'; // need scss from ElementControlPanel
import PreviewPanel from '../PreviewPanel';
import ProductOptionPanel from '../ProductOptionPanel';

import './designPanel.scss';

import classNames from "classnames";
import {connect} from 'react-redux';
import $ from 'jquery';
import Victor from "victor";
import Hotkeys from 'react-hot-keys';
import queryString from 'query-string';
import _ from 'lodash';
import Cookies from 'universal-cookie';

//import ImageFilter from '../ElementControlPanel/ImageFilter/ImageFilter';
//import CheckoutPanel from '../CheckoutPanel'
//import EditTextTool from '../EditTextTool'

import {overlapCache, overlapedIdsByCache} from '../../math/overlapDetection';
import {verticesOfRect} from "../../math/frame";

import {getObjByHash, resolveGroupElement} from '../../utilities/canvasData'
//css and images
import {
    addElementByData,
    addNewPage,
    alignSaveId,
    applyDragImagePreview,
    applyTextForActiveElements,
    auth,
    checkUserSession,
    clearActiveElements,
    clearAllPopup,
    createProject,
    createVirtualGroup,
    deleteActiveElement,
    deleteCurrentPage,
    hidePrintBleed,
    loadBrandKit,
    loadCanvasData,
    loadPrintOnly,
    loadProject,
    loadProjectFonts,
    pasteCopyData,
    reorderData,
    saveHistory,
    setAccessLevel,
    setActiveElement,
    setActiveElementProperty,
    setCallbackUrl,
    setCopyData,
    setCurrentProjectOption,
    // setCustomColor,
    // setCustomFonts,
    setDefaultMode,
    setEngineReady,
    setFocusPage,
    setIsPrintable,
    setMainUrl,
    setPartnershipLink,
    setPartnershipParam,
    setPaymentTerm,
    setPermission,
    setPrintOnlyLoaded,
    setProductName,
    setProjectloaded,
    setProjectName,
    setProjectTarget,
    setProjectWaterMark,
    setRecentUsedImagesList,
    setSaveUrl,
    setSelectFromTeamID,
    setShowDropzone,
    setTransformMode,
    setUserName,
    setZoomScaleByContainerSize,
    toggleItemVisibility,
    ungroupVirtualGroup,
} from '../../actions';

import {resetCheckoutStep} from '../../actions/checkoutActions';
// import { debugPoint } from '../../utilities/debug';


import './AddPage.svg';
import '../ProjectControlPanel/JD Homepage-13.svg';

import './jd_mobile_cover_video.mp4';
import './jd_mobile_cover_video.webm';

//const ElementControlPanel = lazy(() => import('../ElementControlPanel'));
const ImageFilter = lazy(() => import('../ElementControlPanel/ImageFilter/ImageFilter'));
const CheckoutPanel = lazy(() => import('../CheckoutPanel'));
const EditTextTool = lazy(() => import('../EditTextTool'));

class DesignPanel extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            intervalId: 0,
            isFilter: false,
            isAdjust: false,
            print_only: props.print_only,
            no_side: props.no_side,
            is_mobile: props.is_mobile,
            code: (props.print_only ? window.location.pathname.split("/")[2] : ""),
            design_panel: ["design_panel"],
            del_btn_class: this.props.pages && this.props.pages.length > 1 ? 'active_del' : "",
            access_level: 0,
            username: 0,
            permission: "read",
            is_project_loaded: props.is_project_loaded
        };

        this.refreshStats = this.refreshStats.bind(this);
        this.canvasRef = null;
        this.pageRef = [];
        this.loadSaved = this.props.loadSaved;
        this.updateDesignPanel = this.updateDesignPanel.bind(this);
        this.handleSelectionDragStart = this.handleSelectionDragStart.bind(this);
        this.handleSelectionDragMove = this.handleSelectionDragMove.bind(this);
        this.handleSelectionDragEnd = this.handleSelectionDragEnd.bind(this);
        this.handleProductOptionClick = this.handleProductOptionClick.bind(this);
        this.setAccessLevel = this.props.setAccessLevel;
        this.setProjectTarget = this.props.setProjectTarget;
        this.setEngineReady = this.props.setEngineReady;
        this.setPermission = this.props.setPermission;
        this.setUserName = this.props.setUserName;
        this.setPaymentTerm = this.props.setPaymentTerm;
        this.setSelectFromTeamID = this.props.setSelectFromTeamID;
        this.setProductName = this.props.setProductName;
        this.setProjectWaterMark = this.props.setProjectWaterMark;
        this.setIsPrintable = this.props.setIsPrintable;
        this.setCallbackUrl = this.props.setCallbackUrl;
        this.setMainUrl = this.props.setMainUrl;
        this.setSaveUrl = this.props.setSaveUrl;
        this.setPartnershipLink = this.props.setPartnershipLink;
        this.setPartnershipParam = this.props.setPartnershipParam;
        this.dismissCheckoutPanel = this.dismissCheckoutPanel.bind(this);
        this.scrollToPage = this.scrollToPage.bind(this);
        this.handleDeletedPage = this.handleDeletedPage.bind(this);
        this.hotkeyPressed = this.hotkeyPressed.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleDeleteElement = this.handleDeleteElement.bind(this);
        this.handlePosition = this.handlePosition.bind(this);
        this.handleDeselect = this.handleDeselect.bind(this);
        this.handleSelectionDragStart = this.handleSelectionDragStart.bind(this);
        this.handleAddPageBtn = this.handleAddPageBtn.bind(this);
        this.lockCanvas = this.lockCanvas.bind(this);
        this.throttleEvent = this.throttleEvent.bind(this);
        this.initialDistance = 0;
    }

    componentDidMount() {
        var self = this;
        if (this.props.print_only) {
            document.body.style.overflow = 'auto';
        }
        const cookies = new Cookies();
        const intervalId = setInterval(() => this.refreshStats(), 1000);
        this.setState({intervalId: intervalId});

        if (this.state.print_only) {
            console.log("get project", this.state.code);
            this.props.loadPrintOnly(this.state.code, function (json) {
                console.log("load print only");
                if (json == null) {
                    console.log("no project");
                    self.props.setPrintOnlyLoaded();
                    return;
                }
                let waitFonts = [];
                json.forEach(page => {
                    page.elements.forEach(element => {
                        if (element.type === 'text') {
                            var textBold = element.textBold;
                            var textItalic = element.textItalic;
                            var fontStyle = "normal";
                            if (textBold && textItalic) {
                                fontStyle = "bold italic";
                            } else if (textBold) {
                                fontStyle = "bold";
                            } else if (textItalic) {
                                fontStyle = "italic";
                            }
                            let fontStr = fontStyle + ' 1px "' + element.textFontFamily + '"';
                            waitFonts.push(fontStr);
                        }
                    })
                });
                if (process.env.NODE_ENV !== 'production') {
                    console.log(waitFonts);
                }
                self.props.loadCanvasData(json);
                if (!self.props.print_only)
                {
                    let size = self.props.getZoomContainerSize();
                    self.props.setZoomScaleByContainerSize(size.width, null, 'fit');
                }
                var waitCounter = 0;
                let intervalId = setInterval(function () {
                    //console.log("Waited: " + waitCounter);
                    let allComplete = true;
                    $(".element img").each((index, img) => {
                        if (!img.complete) {
                            allComplete = false;
                        }
                    });
                    waitFonts.forEach(font => {
                        if (!document.fonts.check(font)) {
                            allComplete = false;
                        }
                    });
                    if (allComplete || waitCounter > 30000) {
                        clearInterval(intervalId);
                        self.props.setPrintOnlyLoaded();
                        console.log('print only loaded');
                    }
                    waitCounter = waitCounter + 500;
                }, 500);
            });
        } else {
            var token = (window.location.pathname.split("/").length > 3 ? window.location.pathname.split("/")[3] : "");
            var code = (window.location.pathname.split("/").length > 2 ? window.location.pathname.split("/")[2] : "");

            /*if (!code) {
                window.location.href = `${process.env.REACT_APP_MARKETPLACE_API_HOST}`;
                return;
            }*/
            var api_token = cookies.get('api_token')
            if (code && token && (!api_token || api_token === 'guest')) {
                this.props.auth(token, function () {
                    cookies.set('code', code, {path: '/design-builder/' + code});
                    self.props.createProject(function (new_code = null) {
                        if (code) {
                            cookies.set('code', code, {path: '/design-builder/' + code});
                        } else if (new_code) {
                            code = new_code
                            cookies.set('code', code, {path: '/design-builder/' + code});
                        }
                        if (process.env.NODE_ENV !== 'production') {
                            console.log("createProject with code: " + code, window.location.pathname);
                        }
                        self.props.loadProject(code,
                            function (json, access_level, project_name, username, permission,
                                      product_name, custom_fonts, custom_colors, brand_kit, load_fonts_project,
                                      has_watermark, is_printable, callback_url, partnership_link, main_url, save_url, recentlyUsedImages, recentlyUsedPhotos, project_target, payment_term, select_from_team_id, team, partnership_param) {

                                if (load_fonts_project) {
                                    self.props.loadProjectFonts(load_fonts_project);
                                }
                                if (brand_kit && brand_kit.length) {
                                    self.props.loadBrandKit(brand_kit);
                                }

                                if (recentlyUsedImages || recentlyUsedPhotos) {
                                    self.props.setRecentUsedImagesList(recentlyUsedImages, recentlyUsedPhotos);
                                }

                                if (json != null) {
                                    self.props.loadCanvasData(json);
                                    self.props.alignSaveId();
                                }

                                //console.log("project_name: "+project_name);
                                if (!project_name) {
                                    if (!product_name || product_name === "null") {
                                        product_name = "Document name";
                                    }
                                    self.props.setProjectName(product_name + " - Untitled");
                                } else {
                                    self.props.setProjectName(project_name);
                                }


                                self.setProjectTarget(project_target);
                                self.setAccessLevel(access_level);
                                self.setPermission(permission);
                                self.setUserName(username);
                                self.setPaymentTerm(payment_term);
                                self.setSelectFromTeamID(select_from_team_id, team);
                                self.setProductName(product_name);
                                self.setProjectWaterMark(has_watermark);
                                self.setIsPrintable(is_printable);

                                self.setPartnershipLink(partnership_link);
                                self.setPartnershipParam(partnership_param);

                                console.log({callback_url, state: 'one'})
                                self.setCallbackUrl(callback_url);

                                self.setMainUrl(main_url);
                                self.setSaveUrl(save_url);

                                let size = self.props.getZoomContainerSize();
                                self.props.setZoomScaleByContainerSize(size.width, size.height);

                                self.props.checkUserSession();

                                self.setEngineReady(true);
                                self.props.setProjectloaded(true);

                                let applyImg = null;
                                if (window.location.search) {
                                    var params = queryString.parse(window.location.search);
                                    if (params) {
                                        applyImg = params.applyImg;
                                        delete params['applyImg'];
                                        window.history.replaceState(null, "", "?" + queryString.stringify(params));
                                    }
                                }
                                if (applyImg) {
                                    let image = new Image();
                                    image.decoding = 'async';
                                    image.onload = () => {
                                        let pageWidth = (self.props.pages && self.props.pages[0]) ? self.props.pages[0].width : 0;
                                        let pageHeight = (self.props.pages && self.props.pages[0]) ? self.props.pages[0].height : 0;
                                        let imageWidth = image.width;
                                        let imageHeight = image.height;
                                        let scale = 1;
                                        if (imageWidth / imageHeight > pageWidth / pageHeight) {
                                            scale = pageWidth / imageWidth;
                                        } else {
                                            scale = pageHeight / imageHeight;
                                        }
                                        let targetImageWidth = imageWidth * scale;
                                        let targetImageHeight = imageHeight * scale;
                                        setTimeout(function () {
                                            self.props.addElementByData(JSON.stringify({
                                                pages: [{
                                                    width: pageWidth, height: pageHeight, elements: [{
                                                        centerY: pageHeight / 2,
                                                        centerX: pageWidth / 2,
                                                        height: targetImageHeight,
                                                        imageHeight: targetImageHeight,
                                                        imageOriginLeft: 0,
                                                        imageOriginTop: 0,
                                                        imageWidth: targetImageWidth,
                                                        opacity: 1,
                                                        photoAdjustBlur: 0,
                                                        photoAdjustBrightness: 0,
                                                        photoAdjustContrast: 0,
                                                        photoAdjustSaturation: 0,
                                                        photoAdjustTint: 0,
                                                        photoAdjustVignette: 0,
                                                        photoAdjustXProcess: 0,
                                                        photoFilter: "none",
                                                        photoUrl: applyImg,
                                                        rotation: 0,
                                                        scaleX: 1,
                                                        scaleY: 1,
                                                        type: "photo",
                                                        width: targetImageWidth
                                                    }]
                                                }]
                                            }))

                                        }, 2000);
                                    };
                                    image.src = applyImg;
                                }
                            });
                    });

                });
            } else {
                if (code) {
                    cookies.set('code', code, {path: '/design-builder/' + code});
                }
                this.props.createProject(function (new_code = null) {
                    if (new_code) {
                        code = new_code;
                        cookies.set('code', code, {path: '/design-builder/' + code});
                    }
                    //console.log("else createProject with code: " + code, window.location.pathname);
                    self.props.loadProject(code,
                        function (json, access_level, project_name, username, permission,
                                  product_name, custom_fonts, custom_colors, brand_kit, load_fonts_project,
                                  has_watermark, is_printable, callback_url, partnership_link, main_url, save_url, recentlyUsedImages, recentlyUsedPhotos, project_target, payment_term, select_from_team_id, team, partnership_param) {

                            if (load_fonts_project) {
                                self.props.loadProjectFonts(load_fonts_project);
                            }
                            if (brand_kit && brand_kit.length) {
                                self.props.loadBrandKit(brand_kit);
                            }

                            if (recentlyUsedImages || recentlyUsedPhotos) {
                                self.props.setRecentUsedImagesList(recentlyUsedImages, recentlyUsedPhotos);
                            }

                            if (json != null) {
                                self.props.loadCanvasData(json);
                                self.props.alignSaveId();
                            }

                            //console.log("project_name: "+project_name);
                            if (!project_name) {
                                if (!product_name || product_name === "null") {
                                    product_name = "Document name";
                                }
                                self.props.setProjectName(product_name + " - Untitled");
                            } else {
                                self.props.setProjectName(project_name);
                            }


                            self.setProjectTarget(project_target);
                            self.setAccessLevel(access_level);
                            self.setPermission(permission);
                            self.setUserName(username);
                            self.setPaymentTerm(payment_term);
                            self.setSelectFromTeamID(select_from_team_id, team);
                            self.setProductName(product_name);
                            self.setProjectWaterMark(has_watermark);
                            self.setIsPrintable(is_printable);

                            self.setPartnershipLink(partnership_link);
                            self.setPartnershipParam(partnership_param);

                            console.log({callback_url, state: 'two'})
                            self.setCallbackUrl(callback_url);

                            self.setMainUrl(main_url);
                            self.setSaveUrl(save_url);

                            let size = self.props.getZoomContainerSize();
                            self.props.setZoomScaleByContainerSize(size.width, size.height);

                            self.props.checkUserSession();

                            self.setEngineReady(true);
                            self.props.setProjectloaded(true);

                            let applyImg = null;
                            if (window.location.search) {
                                var params = queryString.parse(window.location.search);
                                if (params) {
                                    applyImg = params.applyImg;
                                    delete params['applyImg'];
                                    window.history.replaceState(null, "", "?" + queryString.stringify(params));
                                }
                            }
                            if (applyImg) {
                                let image = new Image();
                                image.decoding = 'async';
                                image.onload = () => {
                                    let pageWidth = (self.props.pages && self.props.pages[0]) ? self.props.pages[0].width : 0;
                                    let pageHeight = (self.props.pages && self.props.pages[0]) ? self.props.pages[0].height : 0;
                                    let imageWidth = image.width;
                                    let imageHeight = image.height;
                                    let scale = 1;
                                    if (imageWidth / imageHeight > pageWidth / pageHeight) {
                                        scale = pageWidth / imageWidth;
                                    } else {
                                        scale = pageHeight / imageHeight;
                                    }
                                    let targetImageWidth = imageWidth * scale;
                                    let targetImageHeight = imageHeight * scale;
                                    setTimeout(function () {
                                        self.props.addElementByData(JSON.stringify({
                                            pages: [{
                                                width: pageWidth, height: pageHeight, elements: [{
                                                    centerY: pageHeight / 2,
                                                    centerX: pageWidth / 2,
                                                    height: targetImageHeight,
                                                    imageHeight: targetImageHeight,
                                                    imageOriginLeft: 0,
                                                    imageOriginTop: 0,
                                                    imageWidth: targetImageWidth,
                                                    opacity: 1,
                                                    photoAdjustBlur: 0,
                                                    photoAdjustBrightness: 0,
                                                    photoAdjustContrast: 0,
                                                    photoAdjustSaturation: 0,
                                                    photoAdjustTint: 0,
                                                    photoAdjustVignette: 0,
                                                    photoAdjustXProcess: 0,
                                                    photoFilter: "none",
                                                    photoUrl: applyImg,
                                                    rotation: 0,
                                                    scaleX: 1,
                                                    scaleY: 1,
                                                    type: "photo",
                                                    width: targetImageWidth
                                                }]
                                            }]
                                        }))

                                    }, 2000);
                                };
                                image.src = applyImg;
                            }
                        });
                });
            }

        }

        if ($(".convas_container").length) {
            $(".convas_container")[0].addEventListener('scroll', this.handleScroll.bind(this));
        }

        // Add an event listener to the document
        document.addEventListener('gesturestart', this.handleGestureStart.bind(this), false);
        document.addEventListener('gesturechange', this.handleGestureChange.bind(this), false);
        document.addEventListener('gestureend', this.handleGestureEnd.bind(this), false);

    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.pages && props.pages.length > 1) {
            if (this.state.del_btn_class!=="active_del")
            {
                this.setState({del_btn_class: "active_del"})
            }
        } else if (this.state.del_btn_class) {
            this.setState({del_btn_class: ""})
        }
    }

    handleGestureStart(event) {
    // Check if it's a two-finger gesture
    if (event.scale !== 1) {
        this.initialDistance = event.scale;
    }
    }
    
    handleGestureChange(event) {
    // Calculate the difference between the initial and current scale
    const scaleDifference = event.scale - this.initialDistance;
    
    // Check if the scale difference is positive or negative
    if (scaleDifference > 0) {
        // Zoom in
        console.log('Zoom in');
    } else if (scaleDifference < 0) {
        // Zoom out
        console.log('Zoom out');
    }
    }
    
    handleGestureEnd() {
    this.initialDistance = 0;
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        if ($(".convas_container").length) {
            $(".convas_container")[0].removeEventListener('scroll', this.handleScroll.bind(this));
        }

        // Add an event listener to the document
        document.removeEventListener('gesturestart', this.handleGestureStart.bind(this), false);
        document.removeEventListener('gesturechange', this.handleGestureChange.bind(this), false);
        document.removeEventListener('gestureend', this.handleGestureEnd.bind(this), false);
    }

    handleScroll() {
        if ($(".convas_container").length) {
            let containerRect = $(".convas_container")[0].getBoundingClientRect();
            let maxLength = -1;
            let focusPage = -1;
            $(".convas_container .page").each((index, page) => {
                let pageRect = page.getBoundingClientRect();
                let pageStart = pageRect.y - containerRect.y;
                let pageEnd = pageRect.y - containerRect.y + pageRect.height;
                let containerStart = 0;
                let containerEnd = containerRect.height;

                let pageLength = Math.min(pageEnd, containerEnd) - Math.max(pageStart, containerStart);
                if (maxLength < pageLength) {
                    maxLength = pageLength;
                    focusPage = index;
                }
            });
            if (focusPage != -1) {
                this.props.setFocusPage(focusPage);
            }
        }
    }

    refreshStats() {
        // console.log(this.state.intervalId);
        clearInterval(this.state.intervalId);
    }

    handleSelectionDragStart(event) {
        if (this.props.editMode === 'crop') return;
        if (event.button === 0) {

            this.overlapInfo = [];
            this.props.pages.forEach((page, index) => {
                this.overlapInfo[index] = overlapCache(page.elements.filter(element => element && element.type !== 'background').map(element => ({
                    id: element.hash,
                    angle: element.rotation,
                    frame: {
                        x: element.centerX - element.width / 2,
                        y: element.centerY - element.height / 2,
                        width: element.width,
                        height: element.height
                    },
                })))
            });

            let startingPointOverlapElement = false;
            $("#page_container .page").each((index, page) => {
                const pageRect = page.getBoundingClientRect();
                let pageOffset = new Victor((event.pageX - pageRect.x) / this.props.zoomScale, (event.pageY - pageRect.y) / this.props.zoomScale);
                const {vertices: selectionVertices} = verticesOfRect(
                    pageOffset,
                    pageOffset
                );
                if (this.overlapInfo[index]) {
                    let overlaps = overlapedIdsByCache(
                        selectionVertices,
                        this.overlapInfo[index]
                    );
                    if (overlaps && overlaps.length) {
                        startingPointOverlapElement = true;
                    }
                }
            });

            if (startingPointOverlapElement) {
                return;
            }

            document.addEventListener('mouseup', this.handleSelectionDragEnd, true);
            document.addEventListener('mousemove', this.handleSelectionDragMove, true);
            document.addEventListener('scroll', this.handleSelectionDragMove, true);

            this.selectedPage = -1;
            this.selectedElements = [];
            let $convas_container = $('.convas_container');
            this.setState({
                selectionBoxStartX: event.pageX,
                selectionBoxStartY: event.pageY,
                selectionBoxStartScrollX: $convas_container.scrollLeft(),
                selectionBoxStartScrollY: $convas_container.scrollTop(),
                selectionBoxEndScrollX: $convas_container.scrollLeft(),
                selectionBoxEndScrollY: $convas_container.scrollTop(),
            })
            // event.preventDefault();
            // event.stopPropagation();
            // return false;
        }

    }

    handleSelectionDragMove(event) {
        let pageX = event.pageX;
        let pageY = event.pageY;
        if (!pageX) {
            pageX = this.state.selectionBoxEndX;
        }
        if (!pageY) {
            pageY = this.state.selectionBoxEndY;
        }
        let currentScrollLeft = $('.convas_container').scrollLeft();
        let currentScrollTop = $('.convas_container').scrollTop();
        let deltaOffsetLeft = currentScrollLeft - this.state.selectionBoxStartScrollX;
        let deltaOffsetTop = currentScrollTop - this.state.selectionBoxStartScrollY;

        if (Math.abs(this.state.selectionBoxStartX - pageX - currentScrollLeft) < 5 && Math.abs(this.state.selectionBoxStartY - pageY - currentScrollTop) < 5) {
            return;
        }
        $("#page_container .page").each((index, page) => {
            if (this.selectedPage !== -1 && this.selectedPage !== index) return;
            const pageRect = page.getBoundingClientRect();
            //let pageOffset = new Victor((pageX - pageRect.x) / this.props.zoomScale, (pageY - pageRect.y) / this.props.zoomScale);

            const {vertices: selectionVertices} = verticesOfRect(
                {
                    x: (this.state.selectionBoxStartX - deltaOffsetLeft - pageRect.x) / this.props.zoomScale,
                    y: (this.state.selectionBoxStartY - deltaOffsetTop - pageRect.y) / this.props.zoomScale
                },
                {x: (pageX - pageRect.x) / this.props.zoomScale, y: (pageY - pageRect.y) / this.props.zoomScale}
            );
            const overlapInfo = this.overlapInfo[index];

            if (overlapInfo) {
                this.selectedElements = overlapedIdsByCache(
                    selectionVertices,
                    overlapInfo
                );
                if (this.selectedElements && this.selectedElements.length) {
                    this.selectedPage = index;
                }
            }
        });

        this.setState({
            showSelectBox: true,
            selectionBoxEndX: pageX,
            selectionBoxEndY: pageY,
            selectionBoxEndScrollX: currentScrollLeft,
            selectionBoxEndScrollY: currentScrollTop,
        });

        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    handleSelectionDragEnd(event) {
        // event.preventDefault();
        // event.stopPropagation();
        document.removeEventListener('mouseup', this.handleSelectionDragEnd, true);
        document.removeEventListener('mousemove', this.handleSelectionDragMove, true);
        document.removeEventListener('scroll', this.handleSelectionDragMove, true);
        this.setState({
            showSelectBox: false,
        });
        let currentScrollLeft = $('.convas_container').scrollLeft();
        let currentScrollTop = $('.convas_container').scrollTop();
        if (Math.abs(this.state.selectionBoxStartX - event.pageX - currentScrollLeft) < 5 && Math.abs(this.state.selectionBoxStartY - event.pageY - currentScrollTop) < 5) {
            return;
        }

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        let relatedElements = [];
        this.selectedElements.forEach(selectedElementHash => {
            let selectedElement = getObjByHash(selectedElementHash, this.props.pages);
            if (!selectedElement || selectedElement.locked) return;
            relatedElements.push(selectedElementHash);
            if (selectedElement && selectedElement.type === 'group' && !selectedElement.isVirtual) {
                selectedElement.elements.forEach(relatedElement => {
                    relatedElements.push(relatedElement);
                })
            }
        });
        relatedElements = relatedElements.filter(onlyUnique);
        if (relatedElements && relatedElements.length) {
            this.selectedElements = relatedElements;
        }

        if (this.selectedElements && this.selectedElements.length) {
            this.props.setActiveElement(this.selectedElements);
            if (this.selectedElements.length > 1) {
                this.props.createVirtualGroup(this.selectedElements);
            }
            this.props.setTransformMode();
        } else {
            let groupElement = resolveGroupElement(this.props.activeElements);
            if (groupElement) {
                this.props.ungroupVirtualGroup();
            }
            this.props.clearActiveElements();
        }
        // return false;
    }

    updateDesignPanel(visible) {
        if (visible === "show") {
            this.setState({design_panel: ["design_panel"]});
        } else {
            this.setState({design_panel: ["design_panel", "collapseMenu"]});
        }

    }

    handleAddPageBtn(e) {
        e.preventDefault();
        const len = this.props.pages ? this.props.pages.length + 1 : 1;
        setTimeout(() => {
            let width = ($($('.page')[0]).parents('.page_break').height());
            // 48 margin-top and bottom
            const srcollto = 48 * len + width * len;
            $('.convas_container').animate({
                scrollTop: (srcollto)
            }, 500);
        }, 50);
        this.props.addNewPage(this.props.pages);

    }

    handleDeletedPage(e, idx) {
        e.preventDefault();
        this.props.deleteCurrentPage([...this.props.pages], idx);
    }

    handleDeleteElement(e) {
        e.preventDefault();
        this.props.activeElements.filter(element => element).forEach(element => {
            this.props.deleteActiveElement(this.props.pages, element.hash);
        })

    }

    handleDeselect() {
        //console.log('deselect');
        if (this.props.editMode === 'crop') return;
        let groupElement = resolveGroupElement(this.props.activeElements);
        if (groupElement) {
            this.props.ungroupVirtualGroup();
        }
        this.props.applyTextForActiveElements();
        this.props.clearActiveElements();
        this.props.setDefaultMode();
        this.props.clearAllPopup();
        this.props.saveHistory();
        if (this.props.showProductOptionPanel) {
            this.props.toggleItemVisibility('productOptionPanel');
        }
    }


    handlePosition(pos) {
        this.props.reorderData(pos);
    }

    hotkeyPressed(keyName, e, handler) {
        if (e.target.id === "link_input") {
            return;
        }
        if (document.activeElement && document.activeElement.tagName == 'INPUT') {
            return;
        }
        switch (handler.key) {
            case "backspace":
            case "del":
                if (document.activeElement && (document.activeElement.tagName.toLowerCase() === 'input' && document.activeElement.type.toLowerCase() === 'text') ||
                    document.activeElement.tagName.toLowerCase() === 'textarea' || document.activeElement.className === "spacing_text_val") {
                    return;
                }
                if (this.props.editMode === 'text') return;
                this.handleDeleteElement(e);
                break;
            case "command+c":
            case "ctrl+c":
                if (this.props.editMode === 'text' && document.activeElement && $(document.activeElement).hasClass('text_input')) return;
                this.props.setCopyData();
                e.preventDefault();
                e.stopPropagation();
                break;
            case "command+v":
            case "ctrl+v":
                if (this.props.editMode === 'text' && document.activeElement && $(document.activeElement).hasClass('text_input')) return;
                this.props.pasteCopyData();
                e.preventDefault();
                e.stopPropagation();
                break;
            case "command+a":
            case "ctrl+a":
                if (document.activeElement && (document.activeElement.tagName.toLowerCase() === 'input' && document.activeElement.type.toLowerCase() === 'text') || document.activeElement.tagName.toLowerCase() === 'textarea') {
                    return;
                }
                if (this.props.editMode === 'text') return;
                this.selectedElements = this.props.pages[this.props.focusPageId].elements.filter(element => element && element.type !== 'background' && !element.locked).map(element => element.hash);
                if (this.selectedElements && this.selectedElements.length) {
                    this.props.setActiveElement(this.selectedElements);
                    if (this.selectedElements.length > 1) {
                        this.props.createVirtualGroup(this.selectedElements);
                    }
                    this.props.setTransformMode();
                } else {
                    let groupElement = resolveGroupElement(this.props.activeElements);
                    if (groupElement) {
                        this.props.ungroupVirtualGroup();
                    }
                    this.props.clearActiveElements();
                }
                e.preventDefault();
                e.stopPropagation();
                break;
        }
    }

    scrollToPage(pageIndex) {
        let offset = $('.page').eq(pageIndex).parents('.page_break').offset();
        if (offset) {
            $('.convas_container').animate({
                scrollTop: (offset.top)
            }, 500);
        }
    }

    throttleEvent(...args) {
        this.throttledEvent = _.throttle(...args);
        return e => {
            e.persist();
            e.preventDefault();
            e.stopPropagation();
            return this.throttledEvent(e);
        };
    }

    onDragOver(event) {
        var hasImage = false;
        // console.log("onDragOver 1");

        if (event && event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items.length) {
            // console.log("onDragOver has dataTransfer");
            if (!this.props.showDropzone)
            {
                for (let i in event.dataTransfer.items) {
                    const { kind, type } = event.dataTransfer.items[i];
                    if (kind === "file") {
                        // const type = event.dataTransfer.items[i].type;
                        // console.log(kind, type);
                        if (type && -1 !== ['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml', 'image/bmp', 'image/webp', 'application/pdf', 'application/postscript'].indexOf(type)) {
                            event.preventDefault();
                            event.stopPropagation();
                            //console.log('add dragged');
                            if (type.match('image/.*')) {
                                hasImage = true;
                            }
                            this.props.setShowDropzone(true);
                            break;
                        }
                    }
                }
            }
        }
        if (hasImage) {
            this.props.clearDragImageData();
        }
    }

    onDragLeave(event) {
        if (!event.relatedTarget) {
            this.props.setShowDropzone(false);
        }
    }

    onDrop(/*event*/) {
        // cannot code here, must in MineElement
        /*if (event && event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items.length) {
            for (let i in event.dataTransfer.items) {
                const { kind, type } = event.dataTransfer.items[i];
                console.log(type);
                /!*if (kind === "file" && (type.match('image/.*') || type === 'application/pdf' || type === 'application/postscript')) {
                    //event.preventDefault();
                    //event.stopPropagation();
                    this.props.setShowDropzone(false);
                    break;
                }*!/
            }
        }*/
    }

    dismissCheckoutPanel() {
        this.props.toggleItemVisibility('checkout');
        this.props.hidePrintBleed();
        this.props.resetCheckoutStep();
    }

    lockCanvas(e) {
        if (this.props.shouldLockCanvas) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }

    handleProjectOptionChange(option, event) {
        this.props.setCurrentProjectOption(option, event.currentTarget.value);
    }

    handleProductOptionClick() {
        this.props.toggleItemVisibility('productOptionPanel');
        //console.log('click productOptionPanel');
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(!this.props.pages && nextProps.pages) {
            return true;
        }
        if(this.props.pages.length !== nextProps.pages.length) {
            return true;
        }
        //if (nextProps.is_project_loaded || this.state.print_only)
        //{
            for (let key in this.props) {
                if (key === 'activeElements') {
                    if (JSON.stringify(this.props[key]) !== JSON.stringify(nextProps[key])) {
                        // console.log('Need Render DesignPanel, props.activeElements', key);
                        return true;
                    }
                // } else if (key === 'filterMode') {
                //    return false;
                } else if ([
                        // 'permission',
                        // 'intervalId',
                        // 'productName',
                        // 'select_from_team_id', //maxim
                        // 'username',
                    ].indexOf(key) !== -1) {
                    return false;
                } else {
                    if (JSON.stringify(this.props[key]) !== JSON.stringify(nextProps[key])) {
                        //console.log('Need Render DesignPanel, props', key);
                        return true;
                    }
                }

            }
        //}
        for (let key in this.state) {
            /*if (key === 'filterMode') {
                return false;
            } else */if (key === 'intervalId') {
                return false;
            } else if (key === 'productName') {
                return false;
            } else if (JSON.stringify(this.state[key]) !== JSON.stringify(nextState[key])) {
                // console.log('Need Render DesignPanel, state', key);
                return true;
            }
        }
        return false;
    }

    render() {
        if (!this.props.pages || !this.props.pages[0]) {
            return '';
        }
        // console.log('ReRender DesignPanel');
        const renderPages = this.props.pages.map((page, index) => {
            let currentDesignBackground = this.props.currentDesignBackground && this.props.currentDesignBackground.canvas && this.props.currentDesignBackground.canvas[index] ? this.props.currentDesignBackground.canvas[index] : null;
            return (
                <Page
                    key={index}
                    data={page}
                    ref={cd => this.pageRef[index] = cd}
                    idx={index}
                    handleDeletedPage={this.handleDeletedPage}
                    scrollToPage={this.scrollToPage}
                    del_btn_class={this.state.del_btn_class}
                    print_only={this.state.print_only}
                    no_side={this.state.no_side}
                    zoomScale={this.props.zoomScale}
                    currentDesignBackground={currentDesignBackground}
                />)
        });
        /*let shouldShowAddNewPageBtn = true;
        if (this.props.pages && this.props.currentDesignBackground && this.props.currentDesignBackground.canvas && this.props.currentDesignBackground.canvas.length <= this.props.pages.length) {
          shouldShowAddNewPageBtn = false;
        }*/
        /*if (this.state.print_only) {
            return (<div className={classNames({print_only_loaded: this.props.print_only_loaded})}>{renderPages}
                <ZoomControl print_only={this.state.print_only} getZoomContainerSize={this.props.getZoomContainerSize}/>
                <div/>
            </div>);
        }*/
        if (this.state.print_only && this.props.pages[0] && this.props.pages[0].width > (window.innerWidth * 0.98)) {
            const newZoom = (window.innerWidth * 0.9) / this.props.pages[0].width;
            const roundZoom = Math.round(newZoom * 100) / 100;
            document.body.style.transform = `scale(${roundZoom})`;
            const factor = 1 / roundZoom;
            const csize = Math.round(factor * 100) / 100 * 100;
            document.body.style.transformOrigin = 'top left';

            document.body.style.width = `${csize}%`;
            document.body.style.height = `${csize}%`;
        }

        let selectionBox = null;
        if (this.state.showSelectBox) {
            const canvasRect = this.canvasRef.getBoundingClientRect();
            let style = {
                left: Math.min(this.state.selectionBoxStartX, this.state.selectionBoxEndX) - canvasRect.x + this.canvasRef.scrollLeft - (this.state.selectionBoxEndX > this.state.selectionBoxStartX ? (this.state.selectionBoxEndScrollX - this.state.selectionBoxStartScrollX) : 0),
                top: Math.min(this.state.selectionBoxStartY, this.state.selectionBoxEndY) - canvasRect.y + this.canvasRef.scrollTop - (this.state.selectionBoxEndY > this.state.selectionBoxStartY ? (this.state.selectionBoxEndScrollY - this.state.selectionBoxStartScrollY) : 0),
                width: Math.abs(this.state.selectionBoxStartX - this.state.selectionBoxEndX + this.state.selectionBoxStartScrollX - this.state.selectionBoxEndScrollX),
                height: Math.abs(this.state.selectionBoxStartY - this.state.selectionBoxEndY + this.state.selectionBoxStartScrollY - this.state.selectionBoxEndScrollY),
            };
            selectionBox = (
                <div className='selection_box' style={style}>
                </div>
            );
        }

        //const mobile_url = `${process.env.REACT_APP_API_HOST}`;
        const pageWidth = this.props.pages[0].width * this.props.zoomScale;
        return (
            <Hotkeys
                keyName="backspace,del,command+a,ctrl+a,command+c,ctrl+c,command+v,ctrl+v"
                onKeyDown={this.hotkeyPressed}
            >
                <section className="section_container" onDragOver={this.throttleEvent(this.onDragOver, 1100)} onDragLeave={this.onDragLeave}
                         onDrop={this.onDrop}>
                    <div className="div_maincontent">
                        <div
                            className={classNames('design_panel_wrapper', 'dummy_design_panel_wrapper_style', {'lock': this.props.shouldLockCanvas})}>
                            {this.props.editTextElement ? <Suspense fallback={<div>Loading...</div>}><EditTextTool/></Suspense> : null}
                            {!this.state.print_only && !this.state.no_side ?
                                <div className={classNames("side_panel_container", this.state.design_panel)}>
                                    <SideControlPanel key="sideControlPanel" updateDesignPanel={this.updateDesignPanel}/>
                                </div> : ""}
                            {this.props.showImageFilter && this.props.showImageFilter.is_display && this.props.activeElements.length ?
                                <Suspense fallback={<div>Loading...</div>}><ImageFilter key={JSON.stringify(this.props.activeElementHashs)}
                                             current_lang={this.props.current_lang}
                                             selectedFilterName={this.props.activeElements[0].filterName}
                                             selectedFilter={this.props.activeElements[0].filter}
                                             editMode={this.props.filterMode}
                                             applyPropertyChange={this.props.setActiveElementProperty}/>
                                </Suspense>
                                : null
                            }
                            <section className={classNames('dummy_canvas_wrapper_style', 'canvas_wrapper')}>
                                <div className="convas_panel">
                                    {(!this.state.print_only && this.props.activeElements && this.props.activeElements.length) ? //<Suspense fallback={<div>Loading...</div>}>
                                        <ElementControlPanel handleDeleteElement={this.handleDeleteElement}
                                                             handlePosition={this.handlePosition}
                                                             handleTextSubmit={this.handleDeselect}
                                        /> : '' // </Suspense>
                                    }
                                    <div
                                        className="convas_container"
                                        ref={cd => this.canvasRef = cd}
                                        onMouseDown={this.handleSelectionDragStart}
                                        onMouseUp={this.handleDeselect} onTouchEnd={this.handleDeselect}>
                                        <div className="convas_layer">
                                            <div className="convas_layer2">
                                                <div className="pages_container">{renderPages}</div>
                                                <div
                                                    className={classNames("add_new_page_container", {hide: this.state.print_only || this.state.no_side})}
                                                    onClick={this.handleAddPageBtn}
                                                    style={{
                                                        width: `${pageWidth}px`,
                                                        marginLeft: (this.state.print_only || this.state.no_side) ? 0 : 50,
                                                        marginRight: (this.state.print_only || this.state.no_side) ? 0 : 50
                                                    }}>
                                                    {(this.props.hidePageOption || !this.props.shouldShowAddNewPageBtn) ? null : (
                                                        <button
                                                            className={classNames("btn_outline_margin btn_outline_container btn_text_style new_page_btn")}>
                                                            <span
                                                                className="btn_title btn_title_fonts">+ {this.props.current_lang === 'en' ? 'Add new Page' : '新增頁面'}</span>
                                                        </button>)}
                                                </div>
                                            </div>
                                        </div>
                                        {selectionBox}
                                        {(this.props.projectOptions && this.props.projectOptions.options && this.props.projectOptions.options.filter(option => parseInt(option.is_show_engine)).length) ? (
                                            <div className="more-btn" onClick={this.handleProductOptionClick}>
                                                <div className="dot"/>
                                                <div className="dot"/>
                                                <div className="dot"/>
                                            </div>) : null}
                                        {this.props.showProductOptionPanel ? (
                                            <div className="product-option-panel-container">
                                                <ProductOptionPanel/>
                                            </div>) : null}
                                    </div>
                                </div>
                                {this.props.currentDesignBackground && this.props.currentDesignBackground.previews && this.props.currentDesignBackground.previews.length ? (
                                    <PreviewPanel/>) : null}

                                {this.state.print_only?null:<div className={classNames("zoom_container")}>
                                    <ZoomControl print_only={this.state.print_only}
                                                 getZoomContainerSize={this.props.getZoomContainerSize}/>
                                </div>}

                            </section>
                            <TabBarPanel/>
                            <div className="design-panel__lock-overlay" onMouseUp={this.lockCanvas}
                                 onMouseDown={this.lockCanvas} onClick={this.lockCanvas}>
                            </div>
                        </div>
                        <Suspense fallback={<span></span>}>
                            {
                                this.props.showCheckoutPanel?<CheckoutPanel handleDismiss={this.dismissCheckoutPanel}/>:null
                            }
                        </Suspense>
                    </div>

                </section>
            </Hotkeys>
        )
    }
}

const mapStateToProps = state => {
    let currentDesignBackground = null;
    if (state.currentProjectOptions && state.projectOptions && state.projectOptions.images) {
        let imageData = state.projectOptions.images.find(image => {
            let match = true;
            for (let option in state.currentProjectOptions) {
                let variation = state.currentProjectOptions[option];
                if (image[option] !== variation) {
                    match = false;
                    break;
                }
            }
            return match;
        });
        if (imageData) {
            currentDesignBackground = imageData;
        }
    }
    return {
        pages: state.pages,
        activeElements: state.activeElements,
        activeElementHashs: state.activeElementHashs,
        filterMode: state.filterMode,
        editMode: state.editMode,
        zoomScale: state.zoomScale,
        showImageFilter: state.showImageFilter,
        print_only_loaded: state.print_only_loaded,
        focusPageId: state.focusPageId,
        access_level: state.access_level,
        permission: state.permission,
        username: state.username,
        payment_term: state.payment_term,
        select_from_team_id: state.select_from_team_id,
        team: state.team,
        engineReady: state.engineReady,
        productName: state.productName,
        shouldLockCanvas: [5, 6, 7, 8].indexOf(state.checkout.checkoutStep) !== -1 && state.itemsVisibility['checkout'],
        showCheckoutPanel: state.itemsVisibility['checkout'],
        is_project_loaded: state.is_project_loaded,
        current_lang: state.current_lang,
        editTextElement: state.editTextElement,
        projectOptions: state.projectOptions,
        hidePageOption: state.hidePageOption,
        currentDesignBackground: currentDesignBackground,
        showDropzone: state.showDropzone,
        showProductOptionPanel: state.itemsVisibility['productOptionPanel'],
    }
};

const mapDispatchToProps = dispatch => ({
    auth: (token, callback) => dispatch(auth(token, callback)),
    clearActiveElements: () => dispatch(clearActiveElements()),
    setActiveElementProperty: (changes) => dispatch(setActiveElementProperty(changes)),
    loadPrintOnly: (data, callback) => dispatch(loadPrintOnly(data, callback)),
    loadCanvasData: (data) => dispatch(loadCanvasData(data)),
    addNewPage: (data) => dispatch(addNewPage(data)),
    deleteCurrentPage: (data, idx) => dispatch(deleteCurrentPage(data, idx)),
    deleteActiveElement: (data, activeHash) => dispatch(deleteActiveElement(data, activeHash)),
    setDefaultMode: () => dispatch(setDefaultMode()),
    reorderData: (dir) => dispatch(reorderData(dir)),
    setActiveElement: (hashs) => dispatch(setActiveElement(hashs)),
    setTransformMode: () => dispatch(setTransformMode()),
    createVirtualGroup: (hashs) => dispatch(createVirtualGroup(hashs)),
    ungroupVirtualGroup: () => dispatch(ungroupVirtualGroup()),
    setPrintOnlyLoaded: () => dispatch(setPrintOnlyLoaded()),
    setFocusPage: (pageId) => dispatch(setFocusPage(pageId)),
    createProject: (data) => dispatch(createProject(data)),
    loadProject: (projectCode, data) => dispatch(loadProject(projectCode, data)),
    alignSaveId: () => dispatch(alignSaveId()),
    clearAllPopup: () => dispatch(clearAllPopup()),
    setAccessLevel: (access_level) => dispatch(setAccessLevel(access_level)),
    setProjectTarget: (project_target) => dispatch(setProjectTarget(project_target)),
    setProjectWaterMark: (has_watermark) => dispatch(setProjectWaterMark(has_watermark)),
    setIsPrintable: (is_printable) => dispatch(setIsPrintable(is_printable)),
    setPermission: (permission) => dispatch(setPermission(permission)),
    setEngineReady: (engineReady) => dispatch(setEngineReady(engineReady)),
    setProductName: (productName) => dispatch(setProductName(productName)),
    setUserName: (username) => dispatch(setUserName(username)),
    setPaymentTerm: (payment_term) => dispatch(setPaymentTerm(payment_term)),
    setSelectFromTeamID: (select_from_team_id, team) => dispatch(setSelectFromTeamID(select_from_team_id, team)),
    setProjectName: (project_name) => dispatch(setProjectName(project_name)),
    setPartnershipLink: (partnership_link) => dispatch(setPartnershipLink(partnership_link)),
    setPartnershipParam: (partnership_param) => dispatch(setPartnershipParam(partnership_param)),
    setCallbackUrl: (callback_url) => dispatch(setCallbackUrl(callback_url)),
    setMainUrl: (main_url) => dispatch(setMainUrl(main_url)),
    setSaveUrl: (save_url) => dispatch(setSaveUrl(save_url)),
    setZoomScaleByContainerSize: (width, height) => dispatch(setZoomScaleByContainerSize(width, height, 'fit')),
    setShowDropzone: (visibility) => dispatch(setShowDropzone(visibility)),
    clearDragImageData: () => dispatch(applyDragImagePreview(null, null)),
    checkUserSession: () => dispatch(checkUserSession()),
    setCopyData: () => dispatch(setCopyData()),
    pasteCopyData: () => dispatch(pasteCopyData()),
    // setCustomFonts: (data) => dispatch(setCustomFonts(data)),
    // setCustomColor: (data) => dispatch(setCustomColor(data)),
    toggleItemVisibility: (itemCode) => dispatch(toggleItemVisibility(itemCode)),
    loadBrandKit: (data) => dispatch(loadBrandKit(data)),
    loadProjectFonts: (data) => dispatch(loadProjectFonts(data)),
    resetCheckoutStep: () => dispatch(resetCheckoutStep()),
    hidePrintBleed: () => dispatch(hidePrintBleed()),
    setProjectloaded: (is_loaded) => dispatch(setProjectloaded(is_loaded)),
    setRecentUsedImagesList: (recentlyUsedImages, recentlyUsedPhotos) => dispatch(setRecentUsedImagesList(recentlyUsedImages, recentlyUsedPhotos)),
    setCurrentProjectOption: (option, variation) => dispatch(setCurrentProjectOption(option, variation)),
    addElementByData: (data, point, page) => dispatch(addElementByData(data, point, page)),
    applyTextForActiveElements: () => dispatch(applyTextForActiveElements()),
    saveHistory: () => dispatch(saveHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesignPanel);
